const initialState = {
  replacementProducts: [],
  editReplacementProductLoading: false,
}

const replacementProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case `SET_REPLACEMENT_PRODUCTS`:
      return {
        ...state,
        replacementProducts: action.data,
      }
    case `SET_EDIT_REPLACEMENT_PRODUCT_LOADING`:
      return {
        ...state,
        editReplacementProductLoading: action.data,
      }

    default:
      return state
  }
}

export default replacementProductReducer
