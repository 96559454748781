import moment from "moment"

const initialState = {
  totalReplacements: 0,
  totalPages: 0,
  isLoading: false,
  replacements: [],

  replacementProducts: [],
  replacementNumber: "",
  warrantyClaimNumber: "",
  replacementMethod: "Warranty Replacement",
  techTeam: "",
  faultyInverterReceived: false,
  letterReceived: false,
  customerName: "",
  faultySerialNumber: "",
  faultyDetails: "",
  currentReplacement: null,
  date: moment().format("YYYY-MM-DD"),
}

const issueReplacementReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_REPLACEMENT_LOADING":
      return {
        ...state,
        isLoading: action.data,
      }
    case "SET_ISSUE_REPLACEMENT_PRODUCTS":
      return {
        ...state,
        replacementProducts: action.data,
      }
    case "ADD_REPLACEMENT_PRODUCT":
      return {
        ...state,
        replacementProducts: action.data,
      }
    case "SAVE_REPLACEMENT":
      return {
        ...state,
        replacementNumber: action.data,
      }
    case "FETCH_REPLACEMENTS":
      return {
        ...state,
        replacements: action.data,
      }
    case `SET_TOTAL_REPLACEMENTS`:
      return {
        ...state,
        totalReplacements: action.data,
      }

    case `SET_TOTAL_REPLACEMENTS_PAGES`:
      return {
        ...state,
        totalPages: action.data,
      }

    case "CLEAR_REPLACEMENT":
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default issueReplacementReducer
