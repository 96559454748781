const initialState = {
  currentTroubleshooting: null,
  troubleshootings: [],
  totalTroubleshootings: 0,
  totalPages: 0,
  loading: false,
  error: null,
}

const troubleshootingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TROUBLESHOOTINGS":
      return {
        ...state,
        troubleshootings: action.data.troubleshootings,
        totalTroubleshootings: action.data.totalTroubleshootings,
        totalPages: action.data.totalPages,
      }

    case "SET_CURRENT_TROUBLESHOOTING":
      return {
        ...state,
        currentTroubleshooting: action.data,
      }

    case "SET_TROUBLESHOOTING_LOADING":
      return {
        ...state,
        loading: action.data,
      }

    case "SET_TROUBLESHOOTING_ERROR":
      return {
        ...state,
        error: action.data,
      }

    default:
      return state
  }
}

export default troubleshootingReducer
